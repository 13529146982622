import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'
import CarouselQuote from '../components/carouselQuote'
import AltSection from '../components/columnAlternating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'

const SolutionsTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.src}
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={post.frontmatter.headline.length >= 1 ? '8' : '12'}>
                <div className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </div>
                <div className="richtext divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>

              {post.frontmatter.headline.length >= 1 && (
                <MDBCol lg="4" md="12" className="pl-lg-5">
                  {post.frontmatter.headline.map((headlines, index) => (
                    <div className="headline-tile mt-lg-3 item-headline" key={index} >
                      <div className="headline-icon"> <FontAwesomeIcon icon={['fal', headlines.icon]} size="2x" />
                      </div>
                      <div className="headline-content">
                        <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium"> {headlines.title} </h3>
                        <p className="font-alt letter-spacing-1 text-xs-medium text-small"> {headlines.description} </p>
                        <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                          <Link to={headlines.link} className="effect-noline">
                            {headlines.linktext}
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && post.frontmatter.section.length > 0 && (
          <>
            {post.frontmatter.section[0].placement == 'left' ||
              (post.frontmatter.section[0].placement == 'right' && (
                <section className="bg-white-grey-gradient">
                  <MDBContainer>
                    {post.frontmatter.section.map((sections, index) => {
                      return (
                        <div>
                          <AltSection
                            key={index}
                            title={sections.title}
                            subtitle={sections.subtitle}
                            description={sections.description}
                            image={sections.image.childImageSharp.gatsbyImageData}
                            imageAltText={sections.alttext}
                            placement={sections.placement}
                            link={sections.link}
                          />
                        </div>
                      )
                    })}
                  </MDBContainer>
                </section>
              ))}
          </>
        )}

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section[0].placement == 'card' && (
              <section className="bg-light-blue">
                <MDBContainer>
                  <MDBRow>
                    <>
                      {post.frontmatter.section.map((sections, index) => {
                        return (
                          <Fragment key={index}>
                            <Card
                              collg="4"
                              colmd="6"
                              height="3rem"
                              title={sections.title}
                              subtitle={sections.subtitle}
                              description={sections.description}
                              image={sections.image.childImageSharp.gatsbyImageData}
                              imageAltText={sections.alttext}
                              placement={sections.placement}
                              link={sections.link}
                            />
                          </Fragment>
                        )
                      })}
                    </>
                  </MDBRow>
                </MDBContainer>
              </section>
            )}
          </>
        )}

        {post.frontmatter.quote && post.frontmatter.quote.length > 0 && (
          <section className="blockback">
            {post.frontmatter.quote.map((quotes, index) => {
              return (
                <CarouselQuote key={index} quotenumber={quotes.quotenumber} strapline={quotes.strapline} author={quotes.author} />
              )
            })}
          </section>
        )}
      </Layout>
    </div>
  )
}
export default SolutionsTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "solutions" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        openingpara
        ctatext
        ctaslug        
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        headline {
          title
          icon
          description
          linktext
          link
        }
        quote {
          quotenumber
          strapline
          author
        }
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          placement
          alttext
          link
        }
      }
      html
    }
  }
`